import React from "react"
import { Link } from "gatsby"
import { Logo } from "instadrops-ui"

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}

const LandingContent = () => {
  return (
    <main style={pageStyles}>
      <title>Página en desarrollo</title>
      <Logo size="small" />
      <h1 style={headingStyles}>404</h1>

      <p style={paragraphStyles}>
        Página no encontrada.
        <br />
        <br />
        <Link to="/">Volver</Link>.
      </p>
    </main>
  )
}

export default LandingContent
